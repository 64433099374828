import type { ComponentProps } from 'react';

/**
 * Types
 */
type CooltixLogoProps = ComponentProps<'svg'> &
  (
    | {
        /**
         * Set this true if you want to use only mono colored version
         * @default false
         */
        mono?: false;

        /**
         * Default color including the text
         * @default '#000'
         */
        mainColor?: string;

        /**
         * @default 'var(--color-cyan-3,#00ffff)'
         */
        ringColor1?: string;

        /**
         * @default '#var(--color-blue-4,#0d7fff)'
         */
        ringColor2?: string;
      }
    | {
        /**
         * Set this true if you want to use only mono colored version
         * @default false
         */
        mono?: true;
      }
  );

/**
 * Component: CooltixLogo
 */
export default function CooltixLogo({
  mono,
  // @ts-expect-error
  mainColor = '#000',
  // @ts-expect-error
  ringColor1 = 'var(--color-cyan-3,#00ffff)',
  // @ts-expect-error
  ringColor2 = 'var(--color-blue-4,#0d7fff)',
  ...props
}: CooltixLogoProps) {
  if (mono)
    return (
      <svg width="148" height="48" viewBox="0 0 148 48" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M23.4845 3.87657C28.3865 3.87657 33.2886 5.72762 36.9548 8.9562C44.7816 15.8008 45.8115 27.9833 39.2617 36.1624C35.5954 40.7254 29.8283 43.5666 24.1848 43.5666C21.1776 43.5666 16.8523 42.7487 13.4744 38.8313C13.1037 38.4008 12.7329 37.9704 12.3622 37.5399C4.24705 28.0694 -0.243056 22.8606 10.0554 10.0324C13.2273 6.07201 18.0057 3.87657 23.4845 3.87657ZM23.4845 1.12152C17.635 1.12152 11.9503 3.4461 8.03687 8.31049C-4.19765 23.5924 2.80528 30.437 11.5383 40.6824C14.875 44.5997 19.4475 46.3647 24.1848 46.3647C30.4462 46.3647 36.996 43.2652 41.239 37.9273C48.7362 28.586 47.5416 14.6815 38.6026 6.84687C34.3596 3.10172 28.8397 1.12152 23.4845 1.12152Z" />
        <path d="M25.3011 47.957C19.8636 47.957 15.0439 45.8046 11.666 41.8442L10.595 40.5958C2.27387 30.867 -4.31711 23.2476 8.12338 7.70734C15.7854 -1.8923 30.6563 -2.62411 40.584 6.07153C50.0585 14.3797 51.2943 29.1882 43.3852 39.0891C39.0186 44.5562 32.3865 47.8709 25.6307 48C25.5483 47.957 25.4247 47.957 25.3011 47.957ZM24.642 2.71381C19.0809 2.71381 13.6845 4.99534 10.1419 9.42925C-0.856844 23.1615 4.16878 29.016 12.5311 38.7447L13.6433 40.0362C16.5681 43.48 20.8522 45.331 25.6307 45.2449C31.5214 45.1589 37.5769 42.1025 41.4079 37.3242C48.3696 28.5424 47.2574 15.499 38.8951 8.18087C34.7345 4.52181 29.6265 2.71381 24.642 2.71381Z" />
        <path d="M24.1875 47.2215C18.9147 47.2215 14.1774 45.1122 10.882 41.2379L9.81092 39.9895C1.61337 30.476 -4.85404 22.9426 7.38049 7.70373C14.8777 -1.68067 29.4603 -2.41248 39.2232 6.11096C48.533 14.247 49.7276 28.7541 41.942 38.4828C37.6578 43.8638 31.1492 47.1354 24.5171 47.2215C24.4347 47.2215 24.3111 47.2215 24.1875 47.2215ZM23.5696 1.97838C17.8849 1.97838 12.3649 4.30296 8.73988 8.86601C-2.54719 22.8996 2.89038 29.2276 11.0879 38.7842L12.159 40.0756C15.2073 43.6055 19.5738 45.4996 24.4759 45.4566C30.4901 45.3705 36.6692 42.271 40.5826 37.3636C47.7503 28.4097 46.5969 15.0649 38.0698 7.57458C33.8268 3.82943 28.5952 1.97838 23.5696 1.97838Z" />
        <path d="M17.0203 23.7114C17.0203 19.7511 20.1922 16.5656 24.0231 16.5226C25.712 16.5226 27.2362 17.0822 28.4308 18.0723C28.8839 18.4597 28.9663 19.1484 28.6367 19.665C28.266 20.1816 27.5657 20.2246 27.1126 19.8802C26.2887 19.1484 25.2177 18.8041 24.0231 18.8041C22.705 18.8041 21.4692 19.3637 20.6041 20.2246C19.7391 21.1286 19.2036 22.3339 19.2036 23.7114C19.2036 25.0459 19.7391 26.2942 20.6041 27.1982C21.5104 28.0591 22.705 28.6187 24.0231 28.6187C25.2177 28.6187 26.2475 28.2313 27.1126 27.5426C27.5657 27.1982 28.266 27.2843 28.6367 27.7578C28.9663 28.2744 28.8839 28.9631 28.4308 29.3505C27.2362 30.3406 25.712 30.9002 24.0231 30.9002C20.1922 30.8572 17.0615 27.6717 17.0203 23.7114Z" />
        <path d="M18.7946 23.7112C18.7946 19.7508 21.9665 16.5654 25.7974 16.5223C27.4863 16.5223 29.0105 17.0819 30.2051 18.072C30.6582 18.4594 30.7406 19.1482 30.411 19.6648C30.0403 20.1813 29.34 20.2244 28.8869 19.88C28.063 19.1482 26.992 18.8038 25.7974 18.8038C24.4793 18.8038 23.2435 19.3634 22.3784 20.2244C21.5134 21.1283 20.9779 22.3337 20.9779 23.7112C20.9779 25.0456 21.5134 26.294 22.3784 27.198C23.2847 28.0589 24.4793 28.6185 25.7974 28.6185C26.992 28.6185 28.0218 28.2311 28.8869 27.5423C29.34 27.198 30.0403 27.284 30.411 27.7576C30.7406 28.2741 30.6582 28.9629 30.2051 29.3503C29.0105 30.3404 27.4863 30.9 25.7974 30.9C22.0077 30.8569 18.8358 27.6715 18.7946 23.7112Z" />
        <path d="M17.9259 23.7112C17.9259 19.7508 21.0978 16.5654 24.9287 16.5223C26.6176 16.5223 28.1418 17.0819 29.3364 18.072C29.7895 18.4594 29.8719 19.1482 29.5423 19.6648C29.1716 20.1813 28.4713 20.2244 28.0182 19.88C27.1943 19.1482 26.1233 18.8038 24.9287 18.8038C23.6105 18.8038 22.3748 19.3634 21.5097 20.2244C20.6447 21.1283 20.1092 22.3337 20.1092 23.7112C20.1092 25.0456 20.6447 26.294 21.5097 27.198C22.416 28.0589 23.6105 28.6185 24.9287 28.6185C26.1233 28.6185 27.1531 28.2311 28.0182 27.5423C28.4713 27.198 29.1716 27.284 29.5423 27.7576C29.8719 28.2741 29.7895 28.9629 29.3364 29.3503C28.1418 30.3404 26.6176 30.9 24.9287 30.9C21.0978 30.8569 17.9259 27.6715 17.9259 23.7112Z" />
        <path d="M63.6731 23.3917C63.7497 19.5412 66.8553 16.4586 70.6635 16.4532C72.2563 16.445 73.8064 16.9742 75.0684 17.9571C75.5238 18.3407 75.6075 19.0155 75.2599 19.5009C74.8855 19.967 74.2177 20.0592 73.7334 19.7117C72.8648 19.005 71.7781 18.6299 70.6635 18.6521C69.3878 18.6624 68.1629 19.1587 67.2331 20.0421C66.3483 20.9277 65.8478 22.1327 65.8418 23.3917C65.84 24.6539 66.3416 25.8635 67.2331 26.7469C68.1629 27.6303 69.3878 28.1266 70.6635 28.1369C71.7776 28.1562 72.8634 27.7814 73.7334 27.0773C74.221 26.7439 74.8786 26.8347 75.2599 27.2881C75.6075 27.7736 75.5238 28.4484 75.0684 28.8319C73.8064 29.8148 72.2563 30.344 70.6635 30.3358C66.847 30.3456 63.7317 27.2509 63.6731 23.3917Z" />
        <path d="M89.0926 18.5168C91.6619 21.2484 91.6619 25.5377 89.0926 28.2693C87.9232 29.5683 86.2725 30.3173 84.5356 30.3372C82.764 30.327 81.0749 29.579 79.866 28.2693C77.3496 25.5165 77.3496 21.2696 79.866 18.5168C81.0711 17.2024 82.7632 16.4551 84.5356 16.4546C86.2733 16.4653 87.9267 17.2135 89.0926 18.5168ZM88.8447 23.393C88.8458 22.1445 88.3834 20.941 87.5492 20.0207C86.7687 19.1502 85.6609 18.6538 84.499 18.6538C83.3371 18.6538 82.2293 19.1502 81.4488 20.0207C80.6078 20.9367 80.1444 22.143 80.1532 23.393C80.1394 24.6389 80.6038 25.8416 81.4488 26.7483C82.2218 27.6319 83.3324 28.1379 84.499 28.1379C85.6656 28.1379 86.7762 27.6319 87.5492 26.7483C88.3877 25.8375 88.851 24.6375 88.8447 23.393Z" />
        <path d="M105.097 18.5159C107.666 21.2475 107.666 25.5368 105.097 28.2685C103.931 29.5683 102.281 30.3177 100.546 30.3363C98.7739 30.3268 97.0845 29.5787 95.8759 28.2685C93.3596 25.5156 93.3596 21.2687 95.8759 18.5159C97.0803 17.2004 98.7729 16.4529 100.546 16.4537C102.281 16.4661 103.932 17.2141 105.097 18.5159ZM104.855 23.3922C104.854 22.143 104.389 20.9396 103.554 20.0198C102.784 19.159 101.693 18.6633 100.546 18.6526C99.3717 18.6458 98.2499 19.1426 97.4588 20.0198C96.6178 20.9359 96.1544 22.1422 96.1632 23.3922C96.1494 24.638 96.6138 25.8407 97.4588 26.7475C98.2307 27.6308 99.3404 28.1367 100.506 28.1367C101.672 28.1367 102.782 27.6308 103.554 26.7475C104.394 25.8372 104.859 24.6373 104.855 23.3922Z" />
        <path d="M110.68 29.2681V17.8749C110.68 17.2897 111.149 16.8153 111.728 16.8153C112.311 16.8121 112.789 17.2847 112.798 17.8749V28.1686H117.963C118.548 28.1686 119.022 28.6481 119.022 29.2396C119.022 29.831 118.548 30.3105 117.963 30.3105H111.728C111.155 30.3106 110.689 29.8465 110.68 29.2681Z" />
        <path d="M127.665 18.9843H124.645V29.2723C124.65 29.5552 124.541 29.828 124.344 30.0286C124.147 30.2293 123.877 30.3407 123.598 30.3376C123.319 30.3376 123.051 30.2252 122.855 30.0252C122.658 29.8253 122.548 29.5543 122.55 29.2723V18.9843H119.536C119.255 18.9813 118.987 18.8656 118.791 18.6626C118.594 18.4596 118.486 18.186 118.489 17.9019C118.484 17.6195 118.593 17.3473 118.79 17.1476C118.988 16.9478 119.257 16.8377 119.536 16.8424H127.676C128.254 16.8424 128.724 17.3168 128.724 17.9019C128.721 18.494 128.25 18.975 127.665 18.9843Z" />
        <path d="M131.968 17.9019C131.963 17.6195 132.072 17.3473 132.27 17.1476C132.467 16.9478 132.736 16.8377 133.016 16.8424C133.598 16.8392 134.074 17.3127 134.08 17.9019V29.2951C134.074 29.8853 133.599 30.3604 133.016 30.3604C132.736 30.3635 132.467 30.2521 132.269 30.0514C132.072 29.8508 131.963 29.578 131.968 29.2951V17.9019Z" />
        <path d="M147.212 18.7035L143.59 23.6709L147.212 28.6839C147.374 28.909 147.44 29.1898 147.397 29.4643C147.353 29.7389 147.204 29.9848 146.981 30.148C146.806 30.282 146.593 30.3576 146.373 30.3644C146.035 30.3582 145.719 30.1942 145.517 29.9201L142.312 25.4654L139.09 29.9201C138.893 30.1886 138.586 30.3521 138.256 30.3644C138.033 30.3641 137.817 30.2879 137.642 30.148C137.176 29.8118 137.065 29.1579 137.394 28.6839L141.016 23.6709L137.394 18.7035C137.064 18.2278 137.175 17.5721 137.642 17.2338C137.86 17.0577 138.141 16.9827 138.417 17.0271C138.693 17.0716 138.937 17.2314 139.09 17.4674L142.312 21.8993L145.517 17.4674C145.673 17.2304 145.92 17.0704 146.199 17.0261C146.477 16.9817 146.761 17.057 146.981 17.2338C147.446 17.5756 147.549 18.233 147.212 18.7035Z" />
      </svg>
    );

  return (
    <svg width="123" height="41" viewBox="0 0 123 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.58 3.79a17.2 17.2 0 0111.23 4.23 16.6 16.6 0 011.92 22.67 16.55 16.55 0 01-12.57 6.17c-2.5 0-6.1-.68-8.92-3.94l-.93-1.08C3.55 23.95-.19 19.61 8.4 8.92c2.64-3.3 6.62-5.13 11.19-5.13zm0-2.3c-4.87 0-9.61 1.94-12.87 6-10.2 12.73-4.36 18.43 2.92 26.97a13.52 13.52 0 0010.53 4.74c5.22 0 10.68-2.59 14.22-7.03a18.95 18.95 0 00-2.2-25.9 19.24 19.24 0 00-12.6-4.78z"
        fill={ringColor1}
      />
      <path
        d="M21.1 40.52c-4.54 0-8.55-1.79-11.37-5.1l-.9-1.03C1.9 26.29-3.58 19.93 6.79 6.99c6.38-8 18.78-8.62 27.05-1.37 7.9 6.92 8.92 19.26 2.33 27.51a19.67 19.67 0 01-14.8 7.43c-.06-.04-.16-.04-.27-.04zm-.56-37.7c-4.63 0-9.13 1.9-12.08 5.6-9.17 11.44-4.98 16.32 2 24.43l.92 1.07c2.44 2.87 6 4.41 9.99 4.34 4.9-.07 9.95-2.62 13.15-6.6 5.8-7.32 4.87-18.19-2.1-24.28a18.01 18.01 0 00-11.88-4.56z"
        fill={ringColor2}
      />
      <path
        d="M20.16 39.91c-4.39 0-8.34-1.76-11.08-4.98l-.9-1.04C1.35 25.96-4.04 19.68 6.16 6.99c6.25-7.83 18.4-8.44 26.53-1.34 7.76 6.78 8.76 18.87 2.27 26.98-3.57 4.48-9 7.21-14.52 7.28h-.28zm-.51-37.7c-4.74 0-9.34 1.94-12.36 5.74-9.4 11.7-4.87 16.97 1.96 24.93l.9 1.08a13.12 13.12 0 0010.26 4.48 18 18 0 0013.42-6.74c5.97-7.46 5-18.58-2.1-24.83a18.35 18.35 0 00-12.08-4.66z"
        fill={mainColor}
      />
      <path
        d="M14.2 20.33c0-3.3 2.63-5.96 5.83-6 1.4 0 2.67.47 3.67 1.3.38.32.45.9.17 1.32a.9.9 0 01-1.27.18 4.08 4.08 0 00-5.42.29 4.22 4.22 0 000 5.81 4.14 4.14 0 002.85 1.19c1 0 1.85-.33 2.57-.9a.93.93 0 011.27.18c.28.43.2 1-.17 1.33a5.7 5.7 0 01-3.67 1.29 5.99 5.99 0 01-5.84-6z"
        fill={ringColor1}
      />
      <path
        d="M15.67 20.33c0-3.3 2.64-5.96 5.83-6 1.41 0 2.68.47 3.68 1.3.38.32.44.9.17 1.32a.9.9 0 01-1.27.18 4.08 4.08 0 00-5.42.29 4.22 4.22 0 000 5.81 4.14 4.14 0 002.84 1.19c1 0 1.86-.33 2.58-.9a.93.93 0 011.27.18c.27.43.2 1-.17 1.32a5.7 5.7 0 01-3.68 1.3 6 6 0 01-5.83-6z"
        fill={ringColor2}
      />
      <path
        d="M14.95 20.33c0-3.3 2.64-5.96 5.83-6 1.4 0 2.68.47 3.67 1.3.38.32.45.9.18 1.32a.9.9 0 01-1.27.18 4.08 4.08 0 00-5.42.29 4.22 4.22 0 000 5.81 4.14 4.14 0 002.84 1.19c1 0 1.86-.33 2.58-.9a.93.93 0 011.27.18c.27.43.2 1-.18 1.32a5.7 5.7 0 01-3.67 1.3 5.96 5.96 0 01-5.83-6zM53.07 20.06a5.87 5.87 0 019.5-4.53c.38.32.45.88.16 1.28a.93.93 0 01-1.28.18 3.93 3.93 0 00-2.55-.88 4.2 4.2 0 00-2.86 1.15 3.98 3.98 0 000 5.6A4.2 4.2 0 0058.9 24c.92.02 1.83-.3 2.55-.88.41-.28.96-.2 1.28.17.29.4.22.97-.16 1.29a5.84 5.84 0 01-9.5-4.53zM74.25 16a5.94 5.94 0 010 8.12 5.19 5.19 0 01-3.8 1.72 5.34 5.34 0 01-3.88-1.72 6.04 6.04 0 010-8.13 5.14 5.14 0 017.69 0zm-.2 4.05c0-1.04-.39-2.04-1.08-2.8a3.41 3.41 0 00-5.09 0 4.11 4.11 0 00-1.07 2.8 4.04 4.04 0 001.07 2.8 3.38 3.38 0 005.09 0 4.1 4.1 0 001.08-2.8zM87.6 16a5.94 5.94 0 010 8.12 5.17 5.17 0 01-3.8 1.72 5.33 5.33 0 01-3.9-1.72 6.04 6.04 0 010-8.13 5.14 5.14 0 017.68 0zm-.21 4.05c0-1.04-.39-2.04-1.09-2.8a3.4 3.4 0 00-5.07 0 4.11 4.11 0 00-1.08 2.8 4.04 4.04 0 001.08 2.8 3.37 3.37 0 005.07 0 4.1 4.1 0 001.09-2.8zM92.24 24.95v-9.5c0-.48.4-.87.88-.87s.88.39.89.88v8.58h4.3c.49 0 .89.4.89.89 0 .5-.4.9-.89.9h-5.2a.88.88 0 01-.87-.88zM106.4 16.38h-2.52v8.57a.86.86 0 01-.87.89.88.88 0 01-.87-.89v-8.57h-2.52a.88.88 0 01-.62-.27.9.9 0 01-.25-.63c0-.24.09-.47.25-.63a.85.85 0 01.62-.26h6.79c.48 0 .87.4.87.89s-.4.9-.88.9zM109.98 15.48a.86.86 0 01.87-.88c.5 0 .89.39.9.88v9.5c-.01.48-.4.88-.9.88a.88.88 0 01-.87-.89v-9.5zM122.69 16.14l-3.02 4.14 3.02 4.18a.88.88 0 01-.2 1.22.88.88 0 01-.5.18.9.9 0 01-.71-.37l-2.68-3.71-2.68 3.71a.9.9 0 01-.7.37.82.82 0 01-.5-.18.89.89 0 01-.21-1.22l3.01-4.18-3.01-4.14a.89.89 0 01.2-1.22.82.82 0 011.2.2l2.7 3.69 2.67-3.7a.84.84 0 011.22-.19c.38.28.47.83.19 1.22z"
        fill={mainColor}
      />
    </svg>
  );
}
